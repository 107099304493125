import React from "react";

export default function Footer(){
    return(
        <footer id="sticky-footer" className="flex-shrink-0 py-4 bg-dark text-white-50 col-md-9 ms-sm-auto col-lg-10 px-md-4 text-black dashboard-card ">
            <div className="container text-center">
                <small className="fw-bolder text-white">Ride&Roll.hu</small>
            </div>
        </footer>
    )
}