export default function DashTutorial(){
    return(
        <div className="mt-lg-5 mt-md-5 mt-sm-0 mt-0">
            <div className="row row-cols-1 g-3">
                <div className="col">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h5 className="fw-bolder">Munkalap feltöltése útmutató</h5>
                            <hr className="hr-circle" />
                            <div className="ratio ratio-16x9">
                                <iframe 
                                    src="https://youtu.be/4h0Lt2GMAXQ" 
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h5 className="fw-bolder">Motor feltöltés útmutató</h5>
                            <hr className="hr-circle" />
                            <div className="ratio ratio-16x9">
                                <iframe 
                                    src="https://www.youtube.com/watch?v=zzLcgdWQ-8A" 
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h5 className="fw-bolder">Motor Törlése útmutató</h5>
                            <hr className="hr-circle" />
                            <div className="ratio ratio-16x9">
                                <iframe 
                                    src="https://www.youtube.com/embed/ZZhoq55mbv0" 
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="fw-bolder">Motor szerkesztése útmutató</h5>
                            <hr className="hr-circle" />
                            <div className="ratio ratio-16x9">
                                <iframe 
                                    src="https://www.youtube.com/embed/ZZhoq55mbv0" 
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}