import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { IoArrowBackCircle } from "react-icons/io5";

export default function EditCollectionPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [collection, setCollection] = useState(null);
    const [name, setName] = useState('');
    const [chassisNumber, setChassisNumber] = useState('');
    const [type, setType] = useState('');
    const [color, setColor] = useState('');
    const [images, setImages] = useState([]);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');
    const [imageToDelete, setImageToDelete] = useState(null);
    const correctPassword = "123//45"; // A helyes jelszó

    useEffect(() => {
        const fetchCollection = async () => {
            try {
                const docRef = doc(db, 'collections', id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setCollection(data);
                    setName(data.name);
                    setChassisNumber(data.chassisNumber);
                    setType(data.type);
                    setColor(data.color);
                    setImages(data.images || []);
                } else {
                    console.error('Nincs ilyen dokumentum!');
                }
            } catch (error) {
                console.error('Hiba a dokumentum lekérdezésében:', error);
            }
        };

        fetchCollection();
    }, [id]);

    const handleSave = async () => {
        try {
            const docRef = doc(db, 'collections', id);
            await updateDoc(docRef, {
                chassisNumber,
                type,
                color,
                images // Képek URL-jeinek mentése
            });
            setShowSuccessPopup(true); // Popup megjelenítése
        } catch (error) {
            console.error('Hiba a dokumentum frissítésében:', error);
        }
    };

    const handleImageChange = async (e) => {
        const files = e.target.files;
        if (files.length === 0) return;

        try {
            const imageUrls = await handleImageUpload(files);
            console.log('Képek feltöltve:', imageUrls);
            setImages(prevImages => [...prevImages, ...imageUrls]);
        } catch (error) {
            console.error('Hiba a képek feltöltése során:', error);
        }
    };

    const handleImageUpload = async (files) => {
        const imageUploadPromises = Array.from(files).map(async (file) => {
            const storageRef = ref(storage, `images/${file.name}`);
            try {
                await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(storageRef);
                return downloadURL;
            } catch (error) {
                console.error('Hiba a kép feltöltésekor:', error);
                return null;
            }
        });

        const urls = await Promise.all(imageUploadPromises);
        return urls.filter(url => url !== null); // Csak a sikeresen feltöltött URL-eket adjuk vissza
    };

    const handleDeleteImage = (imageUrl) => {
        setImageToDelete(imageUrl);
        setShowPasswordModal(true); // Jelszó mező megjelenítése
    };

    const confirmDeleteImage = async () => {
        if (password === correctPassword) {
            try {
                const imageRef = ref(storage, imageToDelete);
                await deleteObject(imageRef);
                setImages(prevImages => prevImages.filter(image => image !== imageToDelete));
                setShowPasswordModal(false);
                setPassword('');
            } catch (error) {
                console.error("Hiba a kép törlésekor:", error);
            }
        } else {
            alert("Hibás jelszó!");
        }
    };

    const closeModal = () => {
        setShowPasswordModal(false);
        setShowSuccessPopup(false);
    };

    if (!collection) return (
        <div className='mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5'>
            <div className="d-flex justify-content-center align-items-center text-center">
                <span className="loader"></span>
                Töltés folyamatban...!
            </div>
        </div>
    );

    return (
        <div className='mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5'>
            <div className='card shadow-sm'>
                <div className='card-body'>
                    <h4 className='fw-bolder'>Robogó szerkesztése</h4>
                    <hr className='hr-circle' />
                    <div className='mb-3'>
                        <label className='form-label fw-bolder'>Márka: <span className='text-danger'>(Nem szerkeszthető)</span></label>
                        <input
                            type='text'
                            className='form-control'
                            value={name}
                            readOnly
                        />
                    </div>
                    <div className='mb-3'>
                        <label className='form-label fw-bolder'>Alvázszám:</label>
                        <input
                            type='text'
                            className='form-control'
                            value={chassisNumber}
                            onChange={(e) => setChassisNumber(e.target.value)}
                        />
                    </div>
                    <div className='mb-3'>
                        <label className='form-label fw-bolder'>Típus:</label>
                        <input
                            type='text'
                            className='form-control'
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        />
                    </div>
                    <div className='mb-3'>
                        <label className='form-label fw-bolder'>Szín:</label>
                        <input
                            type='text'
                            className='form-control'
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor="images" className="form-label fw-bolder">Új képek:</label>
                        <input
                            type="file"
                            className="form-control mb-2"
                            id="images"
                            onChange={handleImageChange}
                            multiple
                        />
                    </div>
                    {images.length > 0 && (
                        <div className="mb-3">
                            <label className="form-label fw-bolder">Feltöltött képek:</label>
                            <div className="image-preview">
                                <div className="row g-2 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-1 justify-content-start">
                                    {images.map((image, index) => (
                                        <div className="col" key={index}>
                                            <div className="image-container" style={{ position: 'relative' }}>
                                                <img src={image} alt={`Uploaded ${index}`} className="img-thumbnail" id='documents-data-image' />
                                                <button 
                                                    type="button" 
                                                    className="btn btn-danger" 
                                                    onClick={() => handleDeleteImage(image)}
                                                    style={{ 
                                                        position: 'absolute', 
                                                        top: '10px', 
                                                        right: '10px' 
                                                    }}
                                                >Törlés</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    <button className='button-unique' onClick={handleSave}>Mentés</button>
                </div>
            </div>

            {/* Jelszó Ellenőrző Modal */}
            {showPasswordModal && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Törlés megerősítése</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>Biztosan törölni szeretnéd ezt a képet?</p>
                                <input
                                    type="password"
                                    placeholder="Jelszó"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={confirmDeleteImage}>Igen</button>
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Nem</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Sikeres Módosítás Modal */}
            {showSuccessPopup && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Sikeresen módosítva!</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>A gyűjtemény sikeresen módosítva lett.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => navigate('/dashboard/collections')}>Vissza a gyűjteményekhez</button>
                                <button type="button" className="btn btn-secondary" onClick={closeModal}><IoArrowBackCircle /> Vissza</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}