
import React from 'react';
import { FaDownload } from "react-icons/fa";
import jsPDF from 'jspdf';

const PDFDownloadButton = ({ collectionName, docData }) => {
    const handleDownloadPDF = () => {
        const pdfDoc = new jsPDF();
        pdfDoc.setFontSize(18);
        pdfDoc.setFont("times", "bold");
        const title = "Javítási Munkalap";
        const pageWidth = pdfDoc.internal.pageSize.getWidth();
        const textWidth = pdfDoc.getStringUnitWidth(title) * pdfDoc.getFontSize() / pdfDoc.internal.scaleFactor;
        const xOffset = (pageWidth - textWidth) / 2;
        pdfDoc.text(title, xOffset, 20);
        pdfDoc.setLineWidth(0.5);
        pdfDoc.line(10, 25, 200, 25);
        pdfDoc.setFontSize(12);
        pdfDoc.setFont("times", "normal");
        pdfDoc.text(`Márka: ${collectionName}`, 10, 35);
        pdfDoc.text(`Típus: ${docData.type}`, 10, 45);
        pdfDoc.text(`Alvázszám: ${docData.chassisNumber}`, 10, 55);
        pdfDoc.text(`Szín: ${docData.color}`, 10, 65);
        pdfDoc.setLineWidth(0.5);
        pdfDoc.line(10, 75, 200, 75);
        pdfDoc.text(`km óra állás: ${docData.name} km`, 10, 85);
        pdfDoc.text(`Dátum: ${docData.state}`, 10, 95);
        pdfDoc.text(`Alkatrészek ár: ${docData.monthrent} Ft`, 10, 105);
        const formattedDescription = docData.description.replace(/,/g, ',\n');
        pdfDoc.text(`Beszerelt alkatrészek: ${formattedDescription}`, 10, 115);
        pdfDoc.setLineWidth(0.5);
        pdfDoc.line(10, 145, 200, 145);
        const cardX = pageWidth - 80;
        const cardY = 180;
        const cardWidth = 70;
        const cardHeight = 40;
        pdfDoc.rect(cardX, cardY, cardWidth, cardHeight);
        pdfDoc.text(`Munkadíj: ${docData.week} Ft`, cardX + 5, cardY + 10);
        pdfDoc.text(`Alkatrészek ár: ${docData.monthrent} Ft`, cardX + 5, cardY + 20);
        pdfDoc.text(`Végösszeg: ${docData.total} Ft`, cardX + 5, cardY + 30);
        pdfDoc.save(`${collectionName}_document_${docData.id}.pdf`);
    };

    return (
        <button className="btn btn-success fw-bolder d-flex align-items-center gap-2 w-100 justify-content-center py-2" style={{backgroundColor:'#0ABAB5 ', border:'0px'}} onClick={handleDownloadPDF}>
            <FaDownload /> Letöltés PDF
        </button>
    );
};

export default PDFDownloadButton;