import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { db } from '../../firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import NotSearch from '../../assets/File searching-rafiki.png';
import './DashCollectionStyles.css';
import { CiEdit } from "react-icons/ci";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";

export default function DashCollectionPage() {
    const [collections, setCollections] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [collectionToProcess, setCollectionToProcess] = useState(null);
    const [action, setAction] = useState(null); // delete or edit
    const [code, setCode] = useState('');
    const navigate = useNavigate();
    const [openCardId, setOpenCardId] = useState(null);

    const toggleCard = (id) => {
        setOpenCardId(openCardId === id ? null : id);
    };

    // Fetch collections from Firestore
    const fetchCollections = async () => {
        try {
            const collectionsRef = collection(db, 'collections');
            const collectionsSnapshot = await getDocs(collectionsRef);
            const collectionsList = collectionsSnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name,
                chassisNumber: doc.data().chassisNumber,
                type: doc.data().type,
                color: doc.data().color,
                images: doc.data().images // Ensure to include images array
            }));

            // Filter based on the searchText for last four digits
            const filteredCollections = collectionsList.filter(collection => {
                const { lastFour } = splitChassisNumber(collection.chassisNumber);
                return lastFour.includes(searchText);
            });

            // Sort the filtered collections by name in ascending order
            const sortedCollections = filteredCollections.sort((a, b) => a.name.localeCompare(b.name));

            setCollections(sortedCollections);
        } catch (error) {
            console.error('Hiba a gyűjtemények lekérdezésében:', error);
        }
    };

    useEffect(() => {
        fetchCollections();
    }, [searchText]);

    const handleSearchChange = (e) => {
        setSearchText(e.target.value.trim());
    };

    const handleProcessClick = (collection, actionType) => {
        setCollectionToProcess(collection);
        setAction(actionType);
        setShowModal(true);
    };

    const confirmAction = async () => {
        if (code === '123//45' && collectionToProcess) {
            try {
                if (action === 'delete') {
                    await deleteDoc(doc(db, 'collections', collectionToProcess.id));
                    setCollections(collections.filter(col => col.id !== collectionToProcess.id));
                } else if (action === 'edit') {
                    navigate(`/dashboard/editcollection/${collectionToProcess.id}`);
                }
                closeModal();
            } catch (error) {
                console.error(`Hiba ${action} collection:, error`);
            }
        } else {
            alert('Hibás kód.');
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setCollectionToProcess(null);
        setCode('');
        setAction(null);
    };

    const splitChassisNumber = (chassisNumber) => {
        if (!chassisNumber) return { firstPart: '', lastFour: '' };
        const length = chassisNumber.length;
        const lastFour = chassisNumber.slice(length - 4);
        const firstPart = chassisNumber.slice(0, length - 4);
        return { firstPart, lastFour };
    };

    return (
        <div className='mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5'>
            <div className="card mb-3 shadow-sm">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-around gap-3">
                        <input
                            type="text"
                            className="form-control custom-input"
                            placeholder="Keresés (alvázszám utolsó 4 számjegy)..."
                            value={searchText}
                            onChange={handleSearchChange}
                            style={{ border: '2px solid #0ABAB5', borderRadius: '8px' }}
                        />
                        <div>
                            <Link to="/dashboard/createcollection" className="btn1 btn-outline-primary1 px-3 p-2" style={{ border: '2px solid #0ABAB5', borderRadius: '8px' }}>
                                <FaPlus />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card shadow-sm'>
                <div className='card-body'>
                    <h4 className='fw-bolder'>Flotta</h4>
                    <hr className='hr-circle-1' />
                    <div>
                        <ul className="list-group">
                            {collections.length > 0 ? (
                                collections.map((collection) => {
                                    const { firstPart, lastFour } = splitChassisNumber(collection.chassisNumber);
                                    const imageUrl = collection.images && collection.images.length > 0 ? collection.images[0] : 'default-image-url.jpg'; // Use the first image in the array
                                    return (
                                        <li key={collection.id} className="list-group-item">
                                            <div className='row justify-content-start'>
                                                <div>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <p
                                                            className='d-flex justify-content-start align-items-center gap-1 mb-1 fw-bolder'
                                                            onClick={() => toggleCard(collection.id)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            Képek 
                                                            {openCardId === collection.id ? 
                                                                <FaArrowAltCircleUp className='dashcollectionlcontentlist-icons' /> 
                                                                : 
                                                                <FaArrowAltCircleDown className='dashcollectionlcontentlist-icons' /> 
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className={`shadow-sm collapse ${openCardId === collection.id ? 'show' : ''}`}>
                                                        <div className="">
                                                            <div className="col-lg-2 col-md-2 col-12 col-sm-12">
                                                                <img src={imageUrl} alt={`${collection.name} ${collection.type}`} className="img-fluid img-thumbnail text-decoration-none border-0 rounded-start" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-8 col-lg-8 col-sm-12 col-12'>
                                                    <div className="">
                                                        <p className='mb-0'>
                                                            <span className='fw-bolder'>Alvázszám:</span> {firstPart}<span className='highlight'>{lastFour}</span>
                                                        </p>
                                                    </div>
                                                    <p className='mb-0'><span className='fw-bolder'>Márka:</span> {collection.name}</p>
                                                    <p className='mb-0'><span className='fw-bolder'>Típus:</span> {collection.type}</p>
                                                    <p className='mb-0'><span className='fw-bolder'>Szín:</span> {collection.color}</p>
                                                </div>
                                                <div className='col-md-4 col-lg-4 col-sm-12 col-12 align-items-center'>
                                                    <div className="d-flex align-items-center buttons-group1">
                                                        <Link 
                                                            to={`/dashboard/collections/${collection.name}`} 
                                                            className='button-unique px-3 py-2'
                                                            id='button-dashcollection-review'
                                                        >Munkalap</Link>
                                                        <button
                                                            onClick={() => handleProcessClick(collection, 'delete')}
                                                            className='btn btn-danger ms-2'
                                                            id='button-dashcollection-delete'
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                        <button
                                                            onClick={() => handleProcessClick(collection, 'edit')}
                                                            className='btn btn-warning ms-2'
                                                            id='button-dashcollection-edit'
                                                        >
                                                            <CiEdit />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                            ) : (
                                <li className="list-group-item">
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='col'>
                                            <p className='mb-0 fw-bold text-center'>Nincs találat a következőre: <span className='fw-bolder text-danger'>{searchText}</span></p>
                                            <img 
                                                src={NotSearch} 
                                                alt="IMAGE" 
                                                className="card-img-top"
                                                id="card-images2"
                                            />
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{action === 'delete' ? 'Törlés megerősítése' : 'Szerkesztés megerősítése'}</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>{action === 'delete' ? 'Biztosan törölni szeretnéd ezt a dokumentumot?' : 'Biztosan szerkeszteni szeretnéd ezt a dokumentumot?'}</p>
                                <input
                                    type="password"
                                    placeholder="jelszó"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={confirmAction}>
                                    {action === 'delete' ? 'Törlés' : 'Szerkesztés'}
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Nem</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

