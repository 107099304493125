

import React, { useState } from 'react';
import { db, storage } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const QRCodeGenerator = () => {
  const [inputText, setInputText] = useState('');
  const [file, setFile] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  const generateQRCode = async () => {
    if (inputText || file) {
      let dataToEncode = inputText;

      if (file) {
        const fileRef = ref(storage, `uploads/${file.name}`);
        const snapshot = await uploadBytes(fileRef, file);
        const fileUrl = await getDownloadURL(snapshot.ref);
        dataToEncode = fileUrl;
      }

      const url = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(dataToEncode)}`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          setQrCodeUrl(url);
          await saveQRCodeToFirestore(url, dataToEncode);
        } else {
          console.error('Failed to fetch QR code');
        }
      } catch (error) {
        console.error('Error fetching QR code:', error);
      }
    }
  };

  const saveQRCodeToFirestore = async (url, data) => {
    try {
      const docRef = await addDoc(collection(db, 'qrCodes'), {
        url,
        data,
        createdAt: new Date()
      });
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <div className="card border-1 shadow-sm mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5">
        <div className="card-body">
            <h3 className='fw-bolder'>QR-kód generátor</h3>
            <hr className='hr-circle' />
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <div className="d-flex justify-content-start flex-wrap">
                    <label htmlFor="exampleFormControlInput1" className="form-label fw-bolder">URL <span className='text-danger' style={{fontSize:'11px'}}>(file feltöltés esetén nem kötelező)</span></label>
                    <input
                        type="text"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        className="form-control mb-3"
                        placeholder='pl: www.rideandroll.hu'
                    />
                    <input
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        className="form-control mb-3"
                    />
                    <button
                        onClick={generateQRCode}
                        className='button-unique w-100'
                    >
                        Generate
                    </button>
                </div>
                {qrCodeUrl && (
                    <div style={{ marginTop: '20px' }}>
                        <h3>Generated QR Code:</h3>
                        <img src={qrCodeUrl} alt="QR Code" />
                    </div>
                )}
            </div>
        </div>
    </div>
  );
};

export default QRCodeGenerator;

