import React, { useEffect, useState } from 'react';
import { db, storage } from '../../firebase'; // Import Firestore and Storage
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import NoDataImage from '../../assets/No data-amico.png'
import ModalImage from 'react-modal-image';
import './DocumentsStyles.css';

export default function DocumentsData() {
    const { id } = useParams();
    const [motor, setMotor] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [imageToDelete, setImageToDelete] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [password, setPassword] = useState('');
    const [deleteImageUrl, setDeleteImageUrl] = useState('');

    useEffect(() => {
        fetchMotorDetails();
    }, [id]);

    const fetchMotorDetails = async () => {
        const docRef = doc(db, 'collections', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setMotor(docSnap.data());
        } else {
            console.log('No such document!');
        }
    };

    const handleImageUpload = async () => {
        if (newImage && motor) {
            // Create a storage reference
            const storageRef = ref(storage, `images/${newImage.name}`);
            // Upload the file
            await uploadBytes(storageRef, newImage);
            // Get the file URL
            const imageUrl = await getDownloadURL(storageRef);
            // Add the image URL to Firestore
            const docRef = doc(db, 'collections', id);
            await updateDoc(docRef, {
                images: arrayUnion(imageUrl),
            });
            setNewImage(null);
            fetchMotorDetails(); // Refresh the motor details after adding image
        }
    };

    const initiateDelete = (imageUrl) => {
        setDeleteImageUrl(imageUrl);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        if (password === '123//45') {
            if (deleteImageUrl && motor) {
                const docRef = doc(db, 'collections', id);
                await updateDoc(docRef, {
                    images: arrayRemove(deleteImageUrl),
                });
                setDeleteImageUrl('');
                setPassword('');
                setShowModal(false);
                fetchMotorDetails(); // Refresh the motor details after deleting image
            }
        } else {
            alert('Helytelen jelszó!');
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setPassword('');
        setDeleteImageUrl('');
    };

    return (
        <div className="mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5">
            {motor ? (
                <div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <h2 className='fw-bolder'>{motor.name}</h2>
                        <div className="d-none d-md-flex justify-content-start">
                            <input
                                type="file"
                                onChange={(e) => setNewImage(e.target.files[0])}
                                className="form-control"
                            />
                            <button onClick={handleImageUpload} className="btn btn-primary">Hozzáadás</button>
                        </div>
                    </div>
                    <p className='mb-0'><strong>Típus:</strong> {motor.type}</p>
                    <p className='mb-0'><strong>Alvázszám:</strong> {motor.chassisNumber}</p>
                    <p className='mb-0'><strong>Szín:</strong> {motor.color}</p>
                    <p className='mb-0'><strong>Képek száma:</strong><span className='fw-bolder text-danger'> {motor.images ? motor.images.length : 0}</span></p>
                    <div className="d-flex d-md-none justify-content-start mt-0 mb-4 flex-wrap">
                        <label htmlFor="collectionName" className="form-label fw-bolder">Kép hozzáadása:</label>
                        <input
                            type="file"
                            onChange={(e) => setNewImage(e.target.files[0])}
                            className="form-control"
                        />
                        <button onClick={handleImageUpload} className="button-unique w-100">Hozzáadás</button>
                    </div>
                    <div className="row row-cols-lg-3 row-cols-md-3 row-cols-sm-1 row-cols-1 g-2">
                        {motor.images && motor.images.length > 0 ? (
                            motor.images.map((imageUrl, index) => (
                                <div key={index} className="col text-center gap-2">
                                    <div className="row">
                                        <div className="col">
                                            <div className="image-container" style={{ position: 'relative' }}>
                                                <ModalImage 
                                                    small={imageUrl}
                                                    large={imageUrl}
                                                    alt={`Motor ${index}`}
                                                    className="card-img-top shadow-sm text-decoration-none"
                                                    id='documents-data-image'
                                                    style={{
                                                        width: '100%',
                                                        height: '400px'
                                                    }}
                                                />
                                                <button 
                                                    onClick={() => initiateDelete(imageUrl)}
                                                    className="btn btn-danger"
                                                    style={{ 
                                                        position: 'absolute', 
                                                        top: '10px', 
                                                        right: '10px' 
                                                    }}
                                                >
                                                    Törlés
                                                </button>
                                            </div>
                                            <div className="py-2">
                                                {/* Ezt a részt elhagyhatod, mivel a gomb már a kép jobb felső sarkában van */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='card w-100 shadow-sm'>
                                <div className='card-body'>
                                    <h3 className='text-center fw-bolder' style={{color:'#0ABAB5'}}>Nincsenek még képek hozzáadva a robogóhoz!</h3>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={NoDataImage} className="card-group-image" alt="" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Modal for delete confirmation */}
                    {showModal && (
                        <div className="modal" style={{ display: 'block' }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Törlés megerősítése</h5>
                                        <button type="button" className="btn-close" onClick={closeModal}></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Biztosan törölni szeretnéd ezt a képet?</p>
                                        <input
                                            type="password"
                                            placeholder="Jelszó"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Igen</button>
                                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Nem</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <p>Betöltés...</p>
            )}
        </div>
    );
}










