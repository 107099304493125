import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";

export default function DashCollectionContentList({ collectionName }) {
    const [collectionData, setCollectionData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleCard = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const fetchCollectionData = async () => {
            try {
                const collectionsRef = collection(db, 'collections');
                const q = query(collectionsRef, where('name', '==', collectionName));
                const querySnapshot = await getDocs(q);
                const collectionList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().name,
                    chassisNumber: doc.data().chassisNumber,  // Update the key to match the DashCollectionPage
                    type: doc.data().type,
                    color: doc.data().color
                }));
                setCollectionData(collectionList[0]);  // Assuming there's only one matching document
            } catch (error) {
                console.error('Hiba a gyűjtési adatok lekérdezésében:', error);
            }
        };

        fetchCollectionData();
    }, [collectionName]);

    if (!collectionData) {
        return <div>Töltés...</div>;
    }

    const splitChassisNumber = (chassisNumber) => {
        if (!chassisNumber) return { firstPart: '', lastFour: '' };
        const length = chassisNumber.length;
        const lastFour = chassisNumber.slice(length - 4);
        const firstPart = chassisNumber.slice(0, length - 4);
        return { firstPart, lastFour };
    };

    const { firstPart, lastFour } = splitChassisNumber(collectionData.chassisNumber);

    return (
        <div>
            <div className="d-flex justify-content-start align-items-center">
                <p
                    className='d-flex justify-content-start align-items-center gap-1 mb-1'
                    onClick={toggleCard}
                    style={{ cursor: 'pointer' }}
                >
                    További részletek   
                    {isOpen ? 
                        <FaArrowAltCircleUp className='dashcollectionlcontentlist-icons' /> 
                        : 
                        <FaArrowAltCircleDown className='dashcollectionlcontentlist-icons' /> 
                    }
                </p>
            </div>
            <div className={`card shadow-sm collapse ${isOpen ? 'show' : ''}`}>
                <div className="card-body">
                    <p className='m-0'><span className='fw-bolder'>Márka:</span> {collectionData.name}</p>
                    <p className='m-0'><span className='fw-bolder'>Típus:</span> {collectionData.type}</p>
                    <p className='m-0'>
                        <span className='fw-bolder'>Alvázszám:</span> {firstPart}<span className='highlight'>{lastFour}</span>
                    </p>
                    <p className='m-0'><span className='fw-bolder'>Szín:</span> {collectionData.color}</p>
                </div>
            </div>
        </div>
    );
}
