import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'; 

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "rentroll-678f8.firebaseapp.com",
  projectId: "rentroll-678f8",
  storageBucket: "rentroll-678f8.appspot.com",
  messagingSenderId: "105494825407",
  appId: "1:105494825407:web:beef4ed37573d91363b90a"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);