import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';

export default function QRCodeList(){
    const [qrCodes, setQrCodes] = useState([]);

    useEffect(() => {
        const fetchQRCodes = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'qrCodes'));
                const qrCodesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setQrCodes(qrCodesList);
            } catch (e) {
                console.error('Error fetching QR codes: ', e);
            }
        };

        fetchQRCodes();
    }, []);

    return (
        <div className="card border-1 shadow-sm mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5">
            <div className="card-body">
                <h3 className='fw-bolder'>QR-kód Lista</h3>
                <hr className='hr-circle' />
                <div className="row row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-2 justify-content-center g-2">
                    {qrCodes.map(qrCode => (
                        <div className="col">
                            <div key={qrCode.id} >
                                <p>{qrCode.text}</p>
                                <img src={qrCode.url} alt="QR Code" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


