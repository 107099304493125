import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';

export default function DashAnalytics() {
    const [collections, setCollections] = useState([]);
    const [workOrders, setWorkOrders] = useState([]);

    // Gyűjtemények lekérése a Firestore-ból
    const fetchCollections = async () => {
        try {
            const collectionsRef = collection(db, 'collections');
            const collectionsSnapshot = await getDocs(collectionsRef);
            const collectionsList = collectionsSnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name,
                chassisNumber: doc.data().chassisNumber
            }));

            // Gyűjtemények rendezése név szerint
            const sortedCollections = collectionsList.sort((a, b) => a.name.localeCompare(b.name));
            setCollections(sortedCollections);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    // Munkalapok lekérése és állapot frissítése
    const fetchWorkOrders = async () => {
        try {
            const workOrdersRef = collection(db, 'workOrders');
            const workOrdersSnapshot = await getDocs(workOrdersRef);
            const workOrdersList = workOrdersSnapshot.docs.map(doc => ({
                id: doc.id,
                total: parseFloat(doc.data().total) || 0,
                collectionId: doc.data().collectionId
            }));
            setWorkOrders(workOrdersList);
        } catch (error) {
            console.error('Error fetching work orders:', error);
        }
    };

    useEffect(() => {
        fetchCollections();
        fetchWorkOrders();
    }, []);

    // Egy adott gyűjteményhez tartozó munkalapok számának és végösszegének kiszámítása
    const calculateTotalsForCollection = (collectionId) => {
        const relevantWorkOrders = workOrders.filter(order => order.collectionId === collectionId);
        const numberOfWorkOrders = relevantWorkOrders.length;
        const totalSum = relevantWorkOrders.reduce((sum, order) => sum + order.total, 0).toFixed(2);
        return { numberOfWorkOrders, totalSum };
    };

    // Az összes gyűjteményhez tartozó munkalapok teljes végösszegének kiszámítása
    const calculateOverallTotalSum = () => {
        const totalSum = workOrders.reduce((sum, order) => sum + order.total, 0);
        return totalSum.toFixed(2);
    };

    return (
        <div className='mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5'>
            <div className='card shadow-sm'>
                <div className='card-body'>
                    <h4 className='fw-bolder'>Motorok és Munkalapok</h4>
                    <hr className='hr-circle-1' />
                    <div>
                        <ul className="list-group">
                            {collections.length > 0 ? (
                                collections.map((collection) => {
                                    const { numberOfWorkOrders, totalSum } = calculateTotalsForCollection(collection.id);

                                    return (
                                        <li key={collection.id} className="list-group-item">
                                            <div className='row justify-content-start'>
                                                <div className='col-md-8 col-lg-8 col-sm-12 col-12'>
                                                    <p className='mb-0'>
                                                        <span className='fw-bolder'>Márka:</span> {collection.name}
                                                    </p>
                                                    <p className='mb-0'>
                                                        <span className='fw-bolder'>Alvázszám:</span> {collection.chassisNumber}
                                                    </p>
                                                </div>
                                                <div className='col-md-4 col-lg-4 col-sm-12 col-12'>
                                                    <p className='mb-0'>
                                                        <span className='fw-bolder'>Munkalapok száma:</span> {numberOfWorkOrders} db
                                                    </p>
                                                    <p className='mb-0'>
                                                        <span className='fw-bolder'>Összes végösszeg:</span> {totalSum} Ft
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                            ) : (
                                <li className="list-group-item">
                                    <p className='mb-0 fw-bold text-center'>Nincs elérhető adat.</p>
                                </li>
                            )}
                        </ul>
                        <hr className='hr-circle-1' />
                        <div className='text-center'>
                            <h5 className="fw-bolder">Összes munkalap: <span className="text-danger fw-bolder">{workOrders.length} db</span></h5>
                            <h5 className="fw-bolder">Összes végösszeg: <span className="text-danger fw-bolder">{calculateOverallTotalSum()} Ft</span></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
