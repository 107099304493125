import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const PaymentStatusToggle = ({ documentId, initialStatus, collectionName }) => {
    const [isPaid, setIsPaid] = useState(initialStatus === "Fizetett");
    const [showModal, setShowModal] = useState(false);
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleStatusChange = () => {
        if (!isPaid) {
            setShowModal(true);
        }
    };

    const handleConfirm = async () => {
        if (password === '123//45') {
            setIsPaid(true);
            setShowModal(false);
            setPassword(''); // clear password field

            try {
                const docRef = doc(db, collectionName, documentId);
                await updateDoc(docRef, {
                    status: "Fizetett"
                });
                console.log("Státusz frissítve: Fizetett");
            } catch (error) {
                console.error('Hiba a státusz frissítésekor:', error);
            }
        } else {
            setErrorMessage('Hibás jelszó!');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPassword('');
        setErrorMessage('');
    };

    return (
        <div className="col-6 col-md-auto col-lg-auto">
            <button
                className="btn fw-bolder d-flex align-items-center gap-2 w-100 justify-content-center py-2"
                style={{
                    backgroundColor: isPaid ? '#28a745' : '#dc3545', // Zöld ha fizetett, piros ha nem fizetett
                    border: '0px',
                    color: 'white'
                }}
                onClick={handleStatusChange}
                disabled={isPaid}
            >
                {isPaid ? "Fizetett" : "Nem fizetett"}
            </button>

            {showModal && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Fizetési státusz megerősítése</h5>
                                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>Biztosan át szeretnéd állítani a státuszt "Fizetett"-re?</p>
                                <input
                                    type="password"
                                    placeholder="Jelszó"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-control"
                                />
                                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" onClick={handleConfirm}>Igen</button>
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Nem</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PaymentStatusToggle;
