import React, { useState } from 'react';
import { db, storage } from '../../firebase';  // Import Firestore and Storage
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { Link, useNavigate } from 'react-router-dom';  
import { IoArrowBackCircle, IoArrowForwardCircleOutline } from "react-icons/io5";

export default function DashAddCollection() {
    const [collectionName, setCollectionName] = useState('');
    const [chassisNumber, setChassisNumber] = useState('');
    const [type, setType] = useState('');
    const [color, setColor] = useState('');
    const [formFilled, setFormFilled] = useState(false); 
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [images, setImages] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const navigate = useNavigate();  

    // Function to check if the collection name already exists
    const isCollectionNameUnique = async (name) => {
        const q = query(collection(db, 'collections'), where('name', '==', name));
        const querySnapshot = await getDocs(q);
        return querySnapshot.empty; 
    };

    // Function to handle image uploads
    const handleImageUpload = async (files) => {
        const imageUploadPromises = Array.from(files).map(async (file) => {
            const storageRef = ref(storage, `images/${file.name}`);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            return downloadURL;
        });

        return await Promise.all(imageUploadPromises);
    };

    const handleAddCollection = async (e) => {
        e.preventDefault();
        if (collectionName.trim() === '' || type.trim() === '' || chassisNumber.trim() === '' || color.trim() === '') {
            alert('Minden mező kitöltése kötelező!');
            return;
        }

        // Check if the collection name is unique
        const isUnique = await isCollectionNameUnique(collectionName);
        if (!isUnique) {
            setErrorMessage('A gyűjtemény már létezik. Kérlek válassz egy másik Márka nevet.');
            return;
        }

        try {
            console.log('Selected files: ', selectedFiles);

            const imageUrls = await handleImageUpload(selectedFiles);

            console.log('Image URLs: ', imageUrls);

            await addDoc(collection(db, 'collections'), { 
                name: collectionName,
                type: type,
                chassisNumber: chassisNumber,
                color: color,
                images: imageUrls
            });

            alert('Gyűjtemény sikeresen hozzáadva!');
            setCollectionName('');
            setType('');
            setChassisNumber('');
            setColor('');
            setImages([]);
            setSelectedFiles([]);
            setFormFilled(false);
            setShowSuccessPopup(true);
        } catch (e) {
            console.error("Hiba a dokumentum hozzáadásában: ", e);
            setErrorMessage('Hiba a gyűjtemény hozzáadásában: ' + e.message);
        }
    };

    const handleDeleteImage = async (imageUrl) => {
        try {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
            setImages(images.filter(image => image !== imageUrl));
        } catch (e) {
            console.error("Hiba a kép törlésekor: ", e);
        }
    };

    // Update formFilled state whenever any of the input fields change
    const handleInputChange = () => {
        setFormFilled(collectionName !== '' && type !== '' && chassisNumber !== '' && color !== '');
    };

    const handleImageChange = (e) => {
        setSelectedFiles(e.target.files);
        setImages(Array.from(e.target.files).map(file => URL.createObjectURL(file)));
    };

    const handleBackClick = () => {
        setShowSuccessPopup(false);
    };

    return (
        <div className='card mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5 shadow-sm'>
            <div className="card-body">
                <h3 className='fw-bolder'>Robogó Feltöltés</h3>
                <hr className='hr-circle' />
                <form onSubmit={handleAddCollection}>
                    <div className="mb-3">
                        <label htmlFor="collectionName" className="form-label fw-bolder">Márka:<span className='text-danger'>*</span></label>
                        <input
                            type="text"
                            className="form-control mb-2 custom-input"
                            id="collectionName"
                            value={collectionName}
                            onChange={(e) => { setCollectionName(e.target.value); handleInputChange(); }}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="type" className="form-label fw-bolder">Típus:<span className='text-danger'>*</span></label>
                        <input
                            type="text"
                            className="form-control mb-2 custom-input"
                            id="type"
                            value={type}
                            onChange={(e) => { setType(e.target.value); handleInputChange(); }}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="chassisNumber" className="form-label fw-bolder">Alvázszám:<span className='text-danger'>*</span></label>
                        <input
                            className="form-control mb-2 custom-input"
                            id="chassisNumber"
                            value={chassisNumber}
                            onChange={(e) => { setChassisNumber(e.target.value); handleInputChange(); }}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="color" className="form-label fw-bolder">Szín:<span className='text-danger'>*</span></label>
                        <input
                            className="form-control mb-2 custom-input"
                            id="color"
                            value={color}
                            onChange={(e) => { setColor(e.target.value); handleInputChange(); }}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="images" className="form-label fw-bolder">Képek:<span className='text-danger'>*</span></label>
                        <input
                            type="file"
                            className="form-control mb-2 custom-input"
                            id="images"
                            onChange={handleImageChange}
                            multiple
                            required
                        />
                    </div>
                    {images.length > 0 && (
                        <div className="mb-3">
                            <label className="form-label fw-bolder">Feltöltött képek:</label>
                            <div className="image-preview">
                                <div className="row g-2 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-1 justify-content-start">
                                    {images.map((image, index) => (
                                        <div className="col">
                                            <div key={index} className="image-container" style={{ position: 'relative' }}>
                                                <img src={image} alt={`Uploaded ${index}`} className="img-thumbnail" id='documents-data-image' />
                                                <button 
                                                    type="button" 
                                                    className="btn btn-danger" 
                                                    onClick={() => handleDeleteImage(image)}
                                                    style={{ 
                                                        position: 'absolute', 
                                                        top: '10px', 
                                                        right: '10px' 
                                                    }}
                                                >Törlés</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {errorMessage && (
                        <div className="alert alert-danger">{errorMessage}</div>
                    )}
                    <button type="submit" className="button-unique-2">Feltöltés</button>
                </form>
                <hr />
                <Link to="/dashboard/collections" className="btn btn-secondary mt-3">Flotta megtekintése</Link>
            </div>
            {showSuccessPopup && (
                <div className="popup">
                    <div className="popup-content p-5">
                        <h2 className='fw-bolder'>Sikeres feltöltés!</h2>
                        <div className="row g-2">
                            <div className="d-flex justify-content-center align-items-center">
                                <Link to="/dashboard/collections/" className="button-unique w-100">Robogók</Link>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <button onClick={handleBackClick} className="button-unique w-100"><IoArrowBackCircle /> Vissza</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

