import React, { useContext, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {auth} from '../../firebase';
import { AuthContext } from "../../contex/AuthContext";
import { Link } from "react-router-dom";
import './LoginStyles.css';
import Logo from "../../assets/Logo.png"
export default function Login(){
    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const {dispatch} = useContext(AuthContext);

    const handlelogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            dispatch({type:"LOGIN", payload:user})
            navigate('/dashboard')
        })
        .catch((error) => {
            console.error("Bejelentkezési hiba", error)
            setError(true);
        });
    }   

    return(
        <div className="login">
            <div className="container">
                <div className="login-form">
                    <div className="card shadow border-0">
                        <div className="card-body">
                            <div className="d-flex justify-content-center">
                                <Link to="/">
                                    <img 
                                        src={Logo}
                                        alt="" 
                                        className="login-logo"
                                        style={{
                                            height:'100px',
                                        }}
                                    />
                                </Link>
                            </div>
                            <h3 className="fw-bolder text-center">Bejelentkezés</h3>
                            <form onSubmit={handlelogin}>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bolder">Email cím</label>
                                    <input 
                                        type="email"
                                        onChange={e => setEmail(e.target.value)}
                                        className="form-control"
                                        id="floatingInput" 
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bolder">Jelszó:</label>
                                    <input 
                                        className="form-control"
                                        htmlFor="exampleFormControlInput1"
                                        type="password"
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                </div>
                                <button 
                                    className="button-unique w-100 fw-bolder" 
                                    type="submit"
                                >Bejelentkezés</button>
                            </form>
                            <div className="d-flex justify-content-center align-items-center my-2">
                                {error && <span className="text-danger text-center fw-bolder">Hibás jelszó vagy email!</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}