import React from "react";
import './DashCollectionStyles.css';
import DashAnalytics from "../charts/DashAnalytics";

export default function DashTotal({ documents }) {

    const calculateTotalSum = () => {
        const totalSum = documents.reduce((sum, doc) => sum + parseFloat(doc.total || 0), 0);
        return totalSum.toFixed(3);
    };

    return (
        <div>
            <h5 className="fw-bolder">Összes munkalap: <span className="text-danger fw-bolder">{documents.length} db</span></h5>
            <hr id="hr-total" />
            <h5 className="fw-bolder">Összes végösszeg: <span className="text-danger fw-bolder">{calculateTotalSum()} Ft</span></h5>
        </div>
    );
}
