import React, { useEffect, useState } from 'react';
import { db } from '../../firebase'; // Import Firestore
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import './DocumentsStyles.css';
import { FaPlus } from 'react-icons/fa'; // Import the FaPlus icon

export default function DocumentsList() {
    const [collections, setCollections] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const fetchCollections = async () => {
            const querySnapshot = await getDocs(collection(db, 'collections'));
            const collectionsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCollections(collectionsData);
        };

        fetchCollections();
    }, []);

    const splitChassisNumber = (chassisNumber) => {
        if (!chassisNumber) return { firstPart: '', lastFour: '' };
        const length = chassisNumber.length;
        const lastFour = chassisNumber.slice(length - 4);
        const firstPart = chassisNumber.slice(0, length - 4);
        return { firstPart, lastFour };
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    // Filtering collections based on search text
    const filteredCollections = collections.filter((collection) => {
        const { lastFour } = splitChassisNumber(collection.chassisNumber);
        return lastFour.includes(searchText);
    });

    return (
        <div className="mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5 shadow-sm">
            <div className="card mb-3 shadow-sm">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-around gap-3">
                        <input
                            type="text"
                            className="form-control custom-input"
                            placeholder="Keresés (alvázszám utolsó 4 számjegy)..."
                            value={searchText}
                            onChange={handleSearchChange}
                            style={{ border: '2px solid #0ABAB5', borderRadius: '8px' }}
                        />
                        <div>
                            <Link to="/dashboard/createcollection" className="btn1 btn-outline-primary1 px-3 p-2" style={{ border: '2px solid #0ABAB5', borderRadius: '8px' }}>
                                <FaPlus />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 row-cols-1">
                {filteredCollections.map((collection) => {
                    const { firstPart, lastFour } = splitChassisNumber(collection.chassisNumber); // Calculate parts inside map
                    const imageUrl = collection.images && collection.images.length > 0 ? collection.images[0] : 'default-image-url.jpg'; // Use the first image in the array
                    return (
                        <div className="col" key={collection.id}>
                            <Link to={`/dashboard/documents/${collection.id}`} className="card-link">
                                <div className="card mb-3">
                                    <img className="card-img-top" src={imageUrl} alt={`${collection.name} ${collection.type}`} />
                                    <div className="card-body">
                                        <h5 className="card-title text-decoration-none"><span className='fw-bolder'>Márka:</span> {collection.name}</h5>
                                        <p className="card-text mb-0"><span className='fw-bolder'>Típus:</span> {collection.type}</p>
                                        <p className='card-text'>
                                            <span className='fw-bolder'>Alvázszám:</span> {firstPart}<span className='highlight'>{lastFour}</span>
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}