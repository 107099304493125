import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './DashAddStyles.css';
import PDFDownloadButton from '../dashaddcollection/PDFDownloadButton';
import { IoArrowBackCircle } from "react-icons/io5";

// Function to format number with dot as thousand separator
const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export default function DashAdd() {
    const { collectionName } = useParams();
    const navigate = useNavigate();
    const [imageUpload, setImageUpload] = useState([]);
    const [week, setWeek] = useState('');
    const [caraddres, setCarAddres] = useState('');
    const [name, setName] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [total, setTotal] = useState('');
    const [vintage, setVintage] = useState('');
    const [fuel, setFuel] = useState('');
    const [description, setDescription] = useState('');
    const [dayrent, setDayRent] = useState('');
    const [monthrent, setMonthRent] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [formFilled, setFormFilled] = useState(false);

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            if (imageUpload.length > 0) {
                const uploadTasks = imageUpload.map((file) => {
                    const storageRef = ref(storage, `images/${file.name}`);
                    const uploadTask = uploadBytesResumable(storageRef, file);
                    return new Promise((resolve, reject) => {
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {},
                            (error) => {
                                console.error('Error uploading image:', error);
                                reject(error);
                            },
                            async () => {
                                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                                resolve(downloadURL);
                            }
                        );
                    });
                });
                const downloadURLs = await Promise.all(uploadTasks);
                await addDoc(collection(db, collectionName), {
                    name,
                    caraddres,
                    state,
                    country,
                    vintage,
                    fuel,
                    description,
                    dayrent,
                    week,
                    monthrent,
                    total,
                    imageUrls: downloadURLs,
                    timeStamp: serverTimestamp(),
                });
            } else {
                await addDoc(collection(db, collectionName), {
                    name,
                    caraddres,
                    state,
                    country,
                    vintage,
                    fuel,
                    description,
                    dayrent,
                    week,
                    monthrent,
                    total,
                    timeStamp: serverTimestamp(),
                });
            }

            // Reset form fields
            setName('');
            setCarAddres('');
            setState('');
            setCountry('');
            setVintage('');
            setTotal('');
            setFuel('');
            setDescription('');
            setDayRent('');
            setWeek('');
            setMonthRent('');
            setImageUpload([]);
            setShowSuccessPopup(true);
        } catch (err) {
            console.error('Error:', err);
            alert('Hiba történt a termék feltöltése közben.');
        }
    };

    const handleNameChange = (e) => setName(e.target.value);
    const handleTotalChange = (e) => setTotal(formatNumber(e.target.value));
    const handleStateChange = (e) => setState(e.target.value);
    const handleDayRentChange = (e) => setDayRent(e.target.value);
    const handleMonthRentChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setMonthRent(value);
        updateTotal(week, value);
    };
    const handleDescriptionChange = (e) => setDescription(e.target.value);
    const handleWeekChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setWeek(value);
        updateTotal(value, monthrent);
    };
    const updateTotal = (weekValue, monthRentValue) => {
        const totalValue = (weekValue || 0) + (monthRentValue || 0);
        setTotal(formatNumber(totalValue.toFixed(2)));
    };

    const handleBackClick = () => {
        navigate(`/dashboard/collections/${collectionName}`);
    };

    const handleInputChange = () => {
        const isFormFilled = (
            name !== '' &&
            state !== '' &&
            dayrent !== '' &&
            week !== '' &&
            monthrent !== '' &&
            total !== '' &&
            description !== ''
        );
        setFormFilled(isFormFilled);
    }
    
    return (
        <div className="card mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5 shadow-sm">
            <div className="card-body">
                <h3 className="fw-bolder">Munkalap Feltöltés</h3>
                <hr className='hr-circle' />
                <form onSubmit={handleAdd} className='card-body'>
                    <div className='row g-2'>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <label htmlFor="name" className="form-label fw-bolder">km óra állás:<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input className='form-control shadow-sm custom-input' type='text' id="name" value={name} onChange={handleNameChange} required />
                                <span className="input-group-text">km</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label htmlFor="state" className="form-label fw-bolder">Dátum:<span className='text-danger'>*</span></label>
                            <input className='form-control shadow-sm custom-input' type="date" id="state" value={state} onChange={handleStateChange} required  />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <label htmlFor="week" className="form-label fw-bolder">Munkadíj:<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input className='form-control shadow-sm custom-input' type='text' id="week" value={week} onChange={handleWeekChange} required  />
                                <span className="input-group-text">Ft</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label htmlFor="monthrent" className="form-label fw-bolder">Alkatrészek ár:<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input className='form-control shadow-sm custom-input' type="text" id="monthrent" value={monthrent} onChange={handleMonthRentChange} required  />
                                <span className="input-group-text">Ft</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label htmlFor="vintage" className="form-label fw-bolder">Végösszeg:<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input className='form-control shadow-sm custom-input' type="text" id="vintage" value={total} onChange={handleTotalChange} required  />
                                <span className="input-group-text">Ft</span>
                            </div>
                        </div>
                        <div className='col-12'>
                            <label htmlFor="description" className="form-label fw-bolder">Elvégzett javítás / alkatrészek:<span className='text-danger'>*</span></label>
                            <textarea
                                className='form-control w-100 shadow-sm custom-input'
                                style={{
                                    maxHeight: '300px',
                                    minHeight: '200px',
                                }}
                                id="description"
                                value={description}
                                onChange={handleDescriptionChange}
                                required 
                            ></textarea>
                        </div>
                    </div>
                    <button type='submit' className='button-unique mt-2 px-3 py-2'>Hozzáadás</button>
                </form>
                {showSuccessPopup && (
                    <div className="popup">
                        <div className="popup-content p-5">
                            <h2 className='fw-bolder'>Sikeres feltöltés!</h2>
                            <button onClick={handleBackClick} className="button-unique"><IoArrowBackCircle /> Vissza</button>
                        </div>
                    </div>
                )}
                {formFilled && (
                    <PDFDownloadButton 
                        collectionName={collectionName}
                        docData={{ fuel, caraddres }}
                        additionalData={{
                            name,
                            state,
                            dayrent,
                            country,
                            monthrent,
                            description,
                            week,
                            total,
                        }}
                    />
                )}
            </div>
        </div>
    );
}



