import React, { useState, useEffect, useRef } from 'react';
import { FaDownload } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import NoDataImage from '../../assets/No data-pana.png';
import PDFDownloadButton from './PDFDownloadButton';
import './DashCollectionStyles.css';
import DashCollectionContentList from './DashCollectionContentList';
import DashCollectionImages from './DashCollectionImages';
import DashTotal from './DashTotal';
import PaymentStatusToggle from './DashPaymentStatusToggle';

export default function DashCollectionContentPage() {
    const { collectionName } = useParams();
    const [documents, setDocuments] = useState([]);
    const [editingDocId, setEditingDocId] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        caraddres: '',
        state: '',
        country: '',
        vintage: '',
        fuel: '',
        description: '',
        dayrent: '',
        week: '',
        monthrent: '',
        total: '',
        updatedAt: new Date().toISOString()
    });
    const [showModal, setShowModal] = useState(false);
    const [deleteDocId, setDeleteDocId] = useState(null);
    const [editDocId, setEditDocId] = useState(null);
    const [password, setPassword] = useState('');
    const [action, setAction] = useState('');

    const componentRef = useRef();

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, collectionName));
                const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const sortedDocs = docs.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                setDocuments(sortedDocs);
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
        };

        fetchDocuments();
    }, [collectionName]);

    const handleEditClick = (doc) => {
        setEditDocId(doc.id);
        setAction('edit');
        setShowModal(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            updatedAt: new Date().toISOString()
        }));
    };

    const handleSaveClick = async () => {
        try {
            const docRef = doc(db, collectionName, editingDocId);
            await updateDoc(docRef, formData);
            setDocuments((prevDocs) => 
                prevDocs.map((doc) => 
                    doc.id === editingDocId ? { ...doc, ...formData } : doc
                ).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            );
            setEditingDocId(null);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    const handleDeleteClick = (docId) => {
        setDeleteDocId(docId);
        setAction('delete');
        setShowModal(true);
    };

    const confirmAction = async () => {
        if (password === '123//45') {
            if (action === 'delete') {
                try {
                    await deleteDoc(doc(db, collectionName, deleteDocId));
                    setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== deleteDocId));
                } catch (error) {
                    console.error('Hiba a dokumentum törlésében:', error);
                }
            } else if (action === 'edit') {
                const doc = documents.find(doc => doc.id === editDocId);
                setEditingDocId(editDocId);
                setFormData({
                    name: doc.name,
                    caraddres: doc.caraddres,
                    state: doc.state,
                    country: doc.country,
                    vintage: doc.vintage,
                    fuel: doc.fuel,
                    description: doc.description,
                    dayrent: doc.dayrent,
                    week: doc.week,
                    monthrent: doc.monthrent, 
                    total: doc.total,
                    updatedAt: doc.updatedAt
                });
            }
            setShowModal(false);
        } else {
            alert('Helytelen jelszó!');
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setPassword('');
    };



    return (
        <div className='mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5'>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h3 className='fw-bolder'>{collectionName} Munkalapok</h3>
                    <hr className='hr-circle' />
                </div>
                <div>
                    <Link to={`/dashboard/${collectionName}/fileupload`} className='button-unique'>Hozzáadás</Link>
                </div>
            </div>
            <div className="d-flex justify-content-start align-items-center mb-0 mt-0 ">
                <DashCollectionContentList collectionName={collectionName} />
            </div>
            <div className="d-flex justify-content-start align-items-center mb-0 mb-2">
                <DashCollectionImages collectionName={collectionName} />
            </div>
            <div ref={componentRef}>
                <div className="row row-cols-lg-1 g-2">
                    {documents.length === 0 ? (
                        <div className='card shadow-sm'>
                            <div className="card-body d-block justify-content-center">
                                <h4 className='text-center fw-bolder'>Ez a motor még nem rendelkezik munkalappal! <br /> 
                                Kérjük, töltse fel az első munkalapot a lenti gombra kattintva.</h4>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={NoDataImage} className="card-group-image" alt="..." />
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <Link className='button-unique' to={`/dashboard/${collectionName}/fileupload`}>Munkalap feltöltése</Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        documents.map((doc) => (
                            <div key={doc.id} className="card shadow-sm">
                                <div className="card-body">
                                    {editingDocId === doc.id ? (
                                        <div>
                                            <label htmlFor="vintage" className="form-label fw-bolder">Km óra állás:</label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                className="form-control mb-2 custom-input"
                                            />
                                            <label htmlFor="vintage" className="form-label fw-bolder">Dátum:</label>
                                            <input
                                                type="text"
                                                name="state"
                                                value={formData.state}
                                                onChange={handleInputChange}
                                                placeholder="State"
                                                className="form-control mb-2 custom-input"
                                            />
                                            <label htmlFor="vintage" className="form-label fw-bolder">Elvégzett javítás:</label>
                                            <input
                                                type="text"
                                                name="dayrent"
                                                value={formData.dayrent}
                                                onChange={handleInputChange}
                                                placeholder="Day Rent"
                                                className="form-control mb-2 custom-input"
                                            />
                                            <label htmlFor="vintage" className="form-label fw-bolder">Munkadíj:</label>
                                            <input
                                                type="text"
                                                name="week"
                                                value={formData.week}
                                                onChange={handleInputChange}
                                                placeholder="Week"
                                                className="form-control mb-2 custom-input"
                                            />
                                            <label htmlFor="vintage" className="form-label fw-bolder">Alkatrész ár:</label>
                                            <input
                                                type="text"
                                                name="monthrent"
                                                value={formData.monthrent}
                                                onChange={handleInputChange}
                                                placeholder="Month Rent"
                                                className="form-control mb-2 custom-input"
                                            />
                                            <label htmlFor="vintage" className="form-label fw-bolder">Végösszeg:</label>
                                            <input
                                                type="text"
                                                name="total"
                                                value={formData.total}
                                                onChange={handleInputChange}
                                                placeholder="Total"
                                                className="form-control mb-2 custom-input"
                                            />
                                            <label htmlFor="vintage" className="form-label fw-bolder">Beszerelt alkatrészek:</label>
                                            <textarea
                                                name="description"
                                                value={formData.description}
                                                onChange={handleInputChange}
                                                placeholder="Description"
                                                className="form-control mb-2 custom-input"
                                            ></textarea>
                                            <button className="button-unique" onClick={handleSaveClick}>
                                                Mentés
                                            </button>
                                        </div>
                                    ) : (
                                        <div>
                                            <p><span className='fw-bolder'>Dátum:</span> {doc.state}</p>
                                            <p><span className='fw-bolder'>Km óra állás:</span> {doc.name} km</p>
                                            <p><span className='fw-bolder'>Alkatrészek ár:</span> {doc.monthrent} Ft</p>
                                            <p><span className='fw-bolder'>Munkadíj:</span> {doc.week} Ft</p>
                                            <p className='text-danger fw-bold'><span className='fw-bolder'>Végösszeg:</span> {doc.total} Ft</p>
                                            <p>
                                            <span className='fw-bolder'>Elvégzett javítás / alkatrészek:<br /></span>
                                                {doc.description.split(',').map((part, index) => (
                                                    <React.Fragment key={index}>
                                                        {part.trim()}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                            <div className="row g-2">
                                                <div className="col-6 col-md-auto col-lg-auto">
                                                    <button className="btn btn-primary fw-bolder d-flex align-items-center gap-2 w-100 justify-content-center py-2" style={{backgroundColor:'#0ABAB5 ', border:'0px'}} onClick={() => handleEditClick(doc)}>
                                                        <MdModeEdit /> Szerkesztés
                                                    </button>
                                                </div>
                                                <div className="col-6 col-md-auto col-lg-auto">
                                                    <PDFDownloadButton className="btn btn-secondary fw-bolder d-flex align-items-center gap-2 w-100 justify-content-center" 
                                                     collectionName={collectionName} docData={doc} />
                                                </div>
                                                <PaymentStatusToggle
                                                    documentId={doc.id} 
                                                    initialStatus={doc.status || "Nem fizetett"} 
                                                    collectionName={collectionName} 
                                                />
                                                <div className="col-6 col-md-auto col-lg-auto">
                                                    <button className="btn btn-warning text-white fw-bolder d-flex align-items-center gap-2 w-100 justify-content-center" onClick={() => handleDeleteClick(doc.id)}>
                                                        <RiDeleteBin6Line /> Törlés
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>

            <div className='mt-4'>
                <DashTotal documents={documents} />
            </div>

            {showModal && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{action === 'delete' ? 'Törlés megerősítése' : 'Szerkesztés megerősítése'}</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>{action === 'delete' ? 'Biztosan törölni szeretnéd ezt a dokumentumot?' : 'Biztosan szerkeszteni szeretnéd ezt a dokumentumot?'}</p>
                                <input
                                    type="password"
                                    placeholder="Jelszó"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={confirmAction}>Igen</button>
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Nem</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

