import React, {useState, useEffect} from "react";
import { auth } from '../../firebase';
import './DashHomeStyles.css';
import Motorbike from '../../assets/AGILITY_50_2021-1024x972 (1) (1).png'
export default function DashHome(){
    const [email, setEmail] = useState('');
    useEffect(() => {
        if (auth.currentUser) {
            setEmail(auth.currentUser.email);
        }
    }, []);

    return(
        <div className="card border-1 shadow-sm mt-lg-5 mt-md-5 mt-sm-0 mt-0 mb-5">
            <div className="card-body">
                <h1 className="dashome-title text-center fw-bolder" style={{color:'#0ABAB5'}}>Üdvözöllek a Főoldalon!</h1>
                <div>
                    <div className="d-flex justify-content-center align-items-center">
                        <img 
                            src={Motorbike} 
                            alt="Robogó" 
                            className="card-img-top"
                            id="card-images1"
                        />
                    </div>
                </div>
                {email && (
                    <h2 className="dashhome-winlogin text-center text-color-success fw-bolder text-success">
                        Sikeres bejelentkezés, <br /> <span className="fs-5 text-decoration-underline" style={{color:'#0ABAB5'}}>{email}</span>
                    </h2>
                )}
            </div>
        </div>
    )
}