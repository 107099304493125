import React, { useState, useContext } from 'react';
import DashNav from '../components/dashboard/DashNav';
import DashSlide from '../components/dashboard/DashSlide';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../src/contex/AuthContext";
import DashHome from '../../src/components/dashhome/DashHome';

export default function Dashboard() {
    const [openMenu, setOpenMenu] = useState(false);
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleOpenMenu = () => {
        setOpenMenu(!openMenu);
    }

    const handleLogout = () => {
        dispatch({ type: "LOGOUT" });
        navigate("/");
    };

    return (
        <div className='overflow-x-hidden'>
            <DashNav handleLogout={handleLogout} handleOpenMenu={handleOpenMenu} />
            <div className="container-fluid">
                <div className="row">
                    <DashSlide openMenu={openMenu} handleOpenMenu={handleOpenMenu} />
                    <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4 text-black dashboard-card mt-3 pt-5 pb-2">
                        <DashHome />
                    </div>
                </div>
            </div>
        </div>
    );
}
