import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation'; // Import the navigation styles
import { Pagination, Navigation } from 'swiper/modules'; // Import the navigation module
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { db } from '../../firebase'; // Import Firebase Firestore
import { collection, query, where, getDocs } from 'firebase/firestore';

export default function DashCollectionImages({ collectionName }) {
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState([]);

    // Function to toggle the visibility of the card
    const toggleCard = () => {
        setIsOpen(!isOpen);
    };

    // Function to fetch images for a specific collection name from Firestore
    const fetchImages = async () => {
        try {
            const q = query(collection(db, 'collections'), where('name', '==', collectionName));
            const querySnapshot = await getDocs(q);
            const fetchedImages = [];

            querySnapshot.forEach(doc => {
                const data = doc.data();
                console.log("Document data:", data); // Debugging output
                if (data.images && Array.isArray(data.images)) {
                    fetchedImages.push(...data.images);
                } else {
                    console.error("Images field is not an array or missing:", data.images);
                }
            });

            setImages(fetchedImages);
        } catch (error) {
            console.error("Error fetching images: ", error);
        }
    };

    useEffect(() => {
        if (collectionName) {
            fetchImages();
        }
    }, [collectionName]);

    return (
        <div>
            <div className="d-flex justify-content-start align-items-center">
                <p
                    className='d-flex justify-content-start align-items-center gap-1 mb-1'
                    onClick={toggleCard}
                    style={{ cursor: 'pointer' }}
                >
                    Képek 
                    {isOpen ? 
                        <FaArrowAltCircleUp className='dashcollectionlcontentlist-icons' /> 
                        : 
                        <FaArrowAltCircleDown className='dashcollectionlcontentlist-icons' /> 
                    }
                </p>
            </div>
            <div className={`card shadow-sm collapse ${isOpen ? 'show' : ''}`} id='dashcollection-image-card'>
                <div className="card-body">
                    <div>
                        <Swiper
                            pagination={{ dynamicBullets: true }}
                            navigation
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {images.length > 0 ? (
                                images.map((image, index) => (
                                    <SwiperSlide key={index} className='d-flex justify-content-center'>
                                        <img src={image} alt={`Slide ${index}`} className="img-thumbnail" style={{ height:'400px' }} />
                                    </SwiperSlide>
                                ))
                            ) : (
                                <SwiperSlide>
                                    <p>Nincs kép</p>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}




