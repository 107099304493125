import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './DashStyles.css';

export default function DashSlide({ openMenu, handleOpenMenu }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [isCodeVerified, setIsCodeVerified] = useState(false);
    const [showCodeModal, setShowCodeModal] = useState(false);
    const [enteredCode, setEnteredCode] = useState('');
    const [redirectPath, setRedirectPath] = useState('');

    const correctCode = '123//45';

    const getButtonClass = (path) => {
        return location.pathname === path ? "nav-link d-flex align-items-center gap-2 active text-white w-100" : "nav-link d-flex align-items-center gap-2 text-white w-100";
    };

    const openCodeModal = (event, path) => {
        event.preventDefault(); // Prevent the default link behavior
        setRedirectPath(path); // Set the redirect path based on the clicked link
        setShowCodeModal(true);
    };

    const closeCodeModal = () => {
        setShowCodeModal(false);
    };

    const handleCodeSubmit = () => {
        if (enteredCode === correctCode) {
            setIsCodeVerified(true);
            closeCodeModal();
            navigate(redirectPath); // Navigate to the set redirect path
        } else {
            alert('Hibás kód!');
        }
    };

    const handleCodeChange = (event) => {
        setEnteredCode(event.target.value);
    };

    return (
        <>
            <div className={`sidebar col-md-3 col-lg-2 bg-dark ${openMenu ? 'show' : ''}`}>
                <div className="d-md-none">
                    <div className={`offcanvas-md offcanvas-end bg-dark ${openMenu ? 'show' : ''}`} 
                        tabIndex="-1" 
                        id="sidebarMenu" 
                        aria-labelledby="sidebarMenuLabel"
                    >
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title text-white" id="sidebarMenuLabel">Ride n' Roll kft.</h5>
                            <button 
                                type="button" 
                                className="btn-close text-reset"
                                onClick={handleOpenMenu}
                                style={{backgroundColor:'#0ABAB5'}}
                            ></button>
                        </div>
                        <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 w-100">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/dashboard" className={getButtonClass("/dashboard")}>
                                        Főoldal
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/dashboard/createcollection" className={getButtonClass("/dashboard/createcollection")}>
                                        Robogó Feltöltés
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/dashboard/collections" className={getButtonClass("/dashboard/collections")}>
                                        Flotta
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link onClick={event => !isCodeVerified ? openCodeModal(event, '/dashboard/documents') : undefined} to={isCodeVerified ? "/dashboard/documents" : "#"} className={getButtonClass("/dashboard/documents")}>
                                        Dokumentumtár
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link onClick={event => !isCodeVerified ? openCodeModal(event, '/dashboard/qrcodegeneration') : undefined} to={isCodeVerified ? "/dashboard/qrcodegeneration" : "#"} className={getButtonClass("/dashboard/qrcodegeneration")}>
                                        QR-kód generálás
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link onClick={event => !isCodeVerified ? openCodeModal(event, '/dashboard/qrcodelist') : undefined} to={isCodeVerified ? "/dashboard/qrcodelist" : "#"} className={getButtonClass("/dashboard/qrcodelist")}>
                                        QR-kód Lista
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/dashboard/edited" className={getButtonClass("/dashboard/edited")}>
                                        Oktató Videók
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="d-none d-md-flex flex-column p-0 pt-lg-3">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link to="/dashboard" className={getButtonClass("/dashboard")}>
                                Főoldal
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard/createcollection" className={getButtonClass("/dashboard/createcollection")}>
                                Robogó Feltöltés
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard/collections" className={getButtonClass("/dashboard/collections")}>
                                Flotta
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={event => !isCodeVerified ? openCodeModal(event, '/dashboard/documents') : undefined} to={isCodeVerified ? "/dashboard/documents" : "#"} className={getButtonClass("/dashboard/documents")}>
                                Dokumentumtár
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={event => !isCodeVerified ? openCodeModal(event, '/dashboard/qrcodegeneration') : undefined} to={isCodeVerified ? "/dashboard/qrcodegeneration" : "#"} className={getButtonClass("/dashboard/qrcodegeneration")}>
                                QR-kód generálás
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={event => !isCodeVerified ? openCodeModal(event, '/dashboard/qrcodelist') : undefined} to={isCodeVerified ? "/dashboard/qrcodelist":"#"} className={getButtonClass("/dashboard/qrcodelist")}>
                                QR-kód Lista
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={event => !isCodeVerified ? openCodeModal(event, '/dashboard/analytics') : undefined} to={isCodeVerified ? "/dashboard/analytics":"#"} className={getButtonClass("/dashboard/analytics")}>
                                Analytikák
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard/edited" className={getButtonClass("/dashboard/edited")}>
                                Oktató Videók
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            {showCodeModal && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Kód Megadása</h5>
                                <button type="button" className="btn-close" onClick={closeCodeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>Kérem, adja meg a hozzáférési kódot:</p>
                                <input
                                    type="password"
                                    placeholder="Kód"
                                    value={enteredCode}
                                    onChange={handleCodeChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={handleCodeSubmit}>OK</button>
                                <button type="button" className="btn btn-secondary" onClick={closeCodeModal}>Mégse</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
