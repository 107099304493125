import React, { useContext } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from '../src/contex/AuthContext';
import Signin from "./router/Signin";
import Dashboard from './router/Dashboard';
import Edited from './router/TutorialVideo';
import AddDetails from './router/AddDetails';
import MotorbikeGroupList from './router/MotorbikeGroupList';
import AddMotor from './router/AddMotor';
import CollectionReview from './router/CollectionReview';
import EditCollection from './router/EditCollection';
import Documents from './router/Documents';
import DocumentsFolders from './router/DocumentsFolders';
import QrCodeGenerator from './router/QrCodeGenerator';
import QrCodeList from './router/QrCodeList';
import Analytics from './router/Analytics';

function App() {
  const {currentUser} = useContext(AuthContext)
  const RequireAuth = ({children}) => {
    return currentUser ? (children) : <Navigate to="/" />
  }
  console.log(currentUser);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route 
          path='/dashboard' 
          element={<RequireAuth><Dashboard /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/collections' 
          element={<RequireAuth><MotorbikeGroupList /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/collections/:collectionName' 
          element={<RequireAuth><CollectionReview /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/:collectionName/fileupload' 
          element={<RequireAuth><AddDetails /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/editcollection/:id' 
          element={<RequireAuth><EditCollection /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/edited' 
          element={<RequireAuth><Edited /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/createcollection' 
          element={<RequireAuth><AddMotor /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/documents' 
          element={<RequireAuth><Documents /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/qrcodegeneration' 
          element={<RequireAuth><QrCodeGenerator /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/analytics' 
          element={<RequireAuth><Analytics /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/qrcodelist' 
          element={<RequireAuth><QrCodeList /></RequireAuth>} 
        />
        <Route 
          path='/dashboard/documents/:id' 
          element={<RequireAuth><DocumentsFolders /></RequireAuth>} 
        />
      </Routes>
    </Router>
  );
}

export default App;
