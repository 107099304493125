import React from "react";
import { BiMenuAltRight } from 'react-icons/bi';
import Logo from '../../assets/Logo.png'
import './DashStyles.css';

export default function DashNav({ handleLogout, handleOpenMenu }) {
    return (
        <header className="navbar sticky-top bg-dark flex-md-nowrap p-0 py-3" id="black-nav" data-bs-theme="dark">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" href="#">
                <img 
                    src={Logo}
                    alt="Logo" 
                    className="navbar-logo-img"
                />
            </a>
            <div className="ms-md-auto me-md-0 d-flex align-items-center">
                <button className="button-unique py-3 px-3 me-0 me-lg-3 me-md-3 me-sm-0" onClick={handleLogout}>Kijelentkezés</button>
                <ul className="navbar-nav flex-row ms-auto d-md-none">
                    <li className="nav-item text-nowrap">
                        <button 
                            className="nav-link px-3 text-white" 
                            type="button" 
                            data-bs-toggle="offcanvas" 
                            data-bs-target="#sidebarMenu" 
                            aria-controls="sidebarMenu" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                            onClick={handleOpenMenu}
                        >
                            <BiMenuAltRight size={30} />
                        </button>
                    </li>
                </ul>
            </div>
        </header>
    );
}
